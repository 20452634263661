<template>
  <div>
    <div class="topimage">
      <el-image style="width:100%" :src="require('@/assets/lianxi/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
    </div>
    <div >
      <div class="appmain" style="margin-top:30px">
        <el-input :placeholder="$t('lianxi.placeholder')" v-model="serachname" @keyup.enter.native="search">
          <template slot="prepend">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </template>
          <el-button slot="append" style="background: #0052D9;color:#ffffff;" @click="search">{{$t('inquire')}}</el-button>
        </el-input>
      </div>
      <div v-if="$i18n.locale != 'en'">
        <div id="dataMapecharts"></div>
        <div class="appmain">
          <div class="shengming">{{$t('shengming')}}</div>
          <el-row :gutter="30">
            <div v-for="(item,index) in list" :key="index">
              <div v-if="item.type==0">
                <el-col :span="24" style="padding-left:0px;">
                  <div class="title">{{item.class_name}}</div>
                </el-col>
                <el-row :gutter="20">
                  <el-col :span="6" :xs="12" v-for="(items,indexs) in item.children" :key="indexs">
                    <div @click="open(items.class_id,item.type)">
                      <div class="xtitle">{{items.class_name}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
          <div id="shijiemap"></div>
          <el-row :gutter="30">
            <div v-for="(item,index) in list" :key="index">
              <div v-if="item.type==1">
                <el-col :span="24" style="padding-left:0px;">
                  <div class="title">{{item.class_name}}</div>
                </el-col>
                <el-row :gutter="20">
                  <el-col :span="6" :xs="12" v-for="(items,indexs) in item.children" :key="indexs">
                    <div @click="open(items.class_id,item.type)">
                      <div class="xtitle">{{items.class_name}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
        </div>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <div class="appmain">
          <div id="shijiemap"></div>
          <div class="shengming">{{$t('shengming')}}</div>
          <el-row :gutter="30">
            <div v-for="(item,index) in list" :key="index">
              <div v-if="item.type==1">
                <el-col :span="24" style="padding-left:0px;">
                  <div class="title">{{item.class_name}}</div>
                </el-col>
                <el-row :gutter="20">
                  <el-col :span="6" :xs="12" v-for="(items,indexs) in item.children" :key="indexs">
                    <div @click="open(items.class_id,item.type)">
                      <div class="xtitle">{{items.class_name}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
          <div id="dataMapecharts"></div>
          <el-row :gutter="30">
            <div v-for="(item,index) in list" :key="index">
              <div v-if="item.type==0">
                <el-col :span="24" style="padding-left:0px;">
                  <div class="title">{{item.class_name}}</div>
                </el-col>
                <el-row :gutter="20">
                  <el-col :span="6" :xs="12" v-for="(items,indexs) in item.children" :key="indexs">
                    <div @click="open(items.class_id,item.type)">
                      <div class="xtitle">{{items.class_name}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-row>
        </div>
      </div>
      <div class="appmain" v-if="$i18n.locale == 'zh' || $i18n.locale == 'hk'">
        <!-- <div id="tengxunmap" v-if="$i18n.locale == 'zh' || $i18n.locale == 'hk'" style="width: 100%;margin-top: 50px;"></div> -->
        <!-- <div style="position:relative;">
          <baidu-map class="bm-view" center="郑州众智科技" :zoom="13" :scroll-wheel-zoom='true' ak="QVv3wMHEgXh9nGuZCfyjGqRhahwWsaGI">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-marker :icon="{url: require('@/assets/lianxi/jinsuolu.png'), size: {width: 70, height: 92}}" title="众智科技股份有限公司" :position="{lng: 113.570653, lat: 34.806515}" @mouseover="openinfo(1)" @mouseout="guanbiinfo()" :dragging="false">

            </bm-marker>
            <bm-overlay pane="labelPane" :class="{sample: true, active}" @draw="draw" @mouseover.native="active = true" @mouseleave.native="active = false">
              <div>
                <div class="infos" v-if="key == 1">
                  <div class="image">
                    <el-image style="width:100%" v-if="key == 1" :src="require('@/assets/guanyu/jinsuolu.png')"></el-image>
                    <el-image style="width:100%" v-if="key == 2" :src="require('@/assets/guanyu/tianjianhu.png')"></el-image>
                  </div>
                  <div>
                    <div class="infotitle" style="font-size: 20px;font-weight: bold;margin-top:20px">{{title}}</div>
                    <div class="infotitle">{{$t('theFreeCall')}}：400-0318-908</div>
                    <div class="infotitle">{{$t('guanyu.theForeignTradeInAStraightLine')}}：0086-371-67981000</div>
                    <div class="infotitle">{{$t('guanyu.phone')}}：0086-371-67988888/67981888</div>
                    <div class="infotitle" style="padding-bottom:20px">{{address}}</div>
                  </div>
                </div>
              </div>
            </bm-overlay>
            <bm-marker :icon="{url: require('@/assets/lianxi/tianjianhu.png'), size: {width: 70, height: 92}}" title="众智科技股份有限公司" :position="{lng: 113.493302, lat: 34.83086}" @mouseover="openinfo(2)" @mouseout="guanbiinfo()" :dragging="false">

            </bm-marker>
            <bm-overlay pane="labelPane" :class="{sample: true, active}" @draw="draw2" @mouseover.native="active = true" @mouseleave.native="active = false">
              <div>
                <div class="infos" v-if="key == 2">
                  <div class="image">
                    <el-image style="width:100%" v-if="key == 1" :src="require('@/assets/guanyu/jinsuolu.png')"></el-image>
                    <el-image style="width:100%" v-if="key == 2" :src="require('@/assets/guanyu/tianjianhu.png')"></el-image>
                  </div>
                  <div>
                    <div class="infotitle" style="font-size: 20px;font-weight: bold;margin-top:20px">{{title}}</div>
                    <div class="infotitle">{{$t('theFreeCall')}}：400-0318-908</div>
                    <div class="infotitle">{{$t('guanyu.theForeignTradeInAStraightLine')}}：0086-371-67981000</div>
                    <div class="infotitle">{{$t('guanyu.phone')}}：0086-371-67988888/67981888</div>
                    <div class="infotitle" style="padding-bottom:20px">{{address}}</div>
                  </div>
                </div>
              </div>
            </bm-overlay>
          </baidu-map>
        </div> -->
      </div>
      <div class="appmain" v-if="$i18n.locale == 'en' || $i18n.locale == 'pt'">
        <!-- <div id="map" style="width: 100%;;height:600px;margin-top:30px;"></div> -->
      </div>
      <el-dialog :title="type == 0 ? '微信扫一扫':''" :visible.sync="dialogVisible" width="40%">
        <div style="display:flex;justify-content: center;flex-wrap: wrap;">
          <div class="dialoglist" :style="{width:type != 0 ? '100%' : ''}" v-for="(item,index) in info" :key="index">
            <div class="dialogimage" v-if="item.wx">
              <el-image style="width:108px;height:108px" :src="item.wx"></el-image>
            </div>
            <div :style="{textAlign: type==0 ? 'center':'left'}">
              <div class="dialoginfo" v-if="item.company">{{$t('lianxi.companyName')}}：{{item.company}}</div>
              <div class="dialoginfo" v-if="item.name">{{$t('lianxi.theContact')}}：{{item.name}}</div>
              <div class="dialoginfo" v-if="item.phone">{{$t('reg.mobilePhone')}}：{{item.phone}}</div>
              <div class="dialoginfo" v-if="item.qq">QQ：{{item.qq}}</div>
              <div class="dialoginfo" v-if="item.skype">skype：{{item.skype}}</div>
              <div class="dialoginfo" v-if="item.email">{{$t('reg.mailbox')}}：{{item.email}}</div>
              <div class="dialoginfo" v-if="item.url">{{$t('lianxi.website')}}：{{item.url}}</div>
              <div class="dialoginfo" v-if="item.addres">{{$t('lianxi.site')}}：{{item.addres}}</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- <div v-if="$i18n.locale == 'en'">
      <div class="appmain" style="margin-top:30px">
        <div>
          <div style="font-size: 24px;font-family: SourceHanSansCN-Bold, SourceHanSansCN;font-weight: bold;color: #333333;">Contact Information</div>
          <div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #666666;margin-top:10px;">If you'd like to get in touch, please give us a call, drop us an email or submit our online form.</div>
        </div>
        <div style="margin-top:30px;">
          <div style="font-size: 24px;font-family: SourceHanSansCN-Bold, SourceHanSansCN;font-weight: bold;color: #333333;">Tel</div>
          <div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #666666;margin-top:10px;">0086-371-67981000</div>
        </div>
        <div style="margin-top:30px;">
          <div style="font-size: 24px;font-family: SourceHanSansCN-Bold, SourceHanSansCN;font-weight: bold;color: #333333;">Email</div>
          <div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #666666;margin-top:10px;">sales@smartgen.cn</div>
        </div>
        <div style="margin-top:30px;">
          <div style="font-size: 24px;font-family: SourceHanSansCN-Bold, SourceHanSansCN;font-weight: bold;color: #333333;">Add</div>
          <div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #666666;margin-top:10px;">No.28 Jinsuo Road, Zhengzhou City, Henan Province</div>
        </div>
        <div style="margin-top:30px;">
          <div style="font-size: 24px;font-family: SourceHanSansCN-Bold, SourceHanSansCN;font-weight: bold;color: #333333;">Info Request</div>
          <div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #666666;margin-top:10px;">For all inquiries, please fill in the form below(* are required) to send us a brief message, and we will get back to you as soon as possible.</div>
        </div>
        <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="100px" style="margin-top:30px">
            <el-row :gutter="100">
              <el-col :span="12">
                <el-form-item :label="$t('reg.name')" prop="name">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('reg.mobilePhone')" prop="shouji">
                  <el-input v-model="form.shouji"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('reg.mailbox')" prop="youxiang">
                  <el-input v-model="form.youxiang"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('reg.companyName')" prop="company">
                  <el-input v-model="form.company"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="country" prop="country">
                  <el-input v-model="form.country"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('reg.address')" prop="address">
                  <el-input v-model="form.address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('fankui.improvementSuggestions')" prop="jianyi">
                  <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" v-model="form.jianyi"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <div style="width: 350px;height: 48px;margin:0 auto;background: #0052D9;text-align: center;line-height: 48px;color: #fff;margin-top: 3%;" @click="onSubmit">{{$t('fankui.submit')}}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import TMap from "@/utils/initMap";
import { Message } from "element-ui";
import { contactusList, bsUserList } from "@/api/api";
import { productscollectAdd } from "@/api/api";
import * as echarts from "echarts";
import china from "./china.json";
import china_en from "./china_en.json";
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { BmControl, BmMarker, BmLabel, BmDriving, BmOverlay, BmNavigation } from 'vue-baidu-map'
import world from "./world.json"
import '/node_modules/echarts/map/js/world.js'
export default {
  components: {
    BaiduMap,
    BmControl,
    BmMarker,
    BmLabel,
    BmDriving,
    BmOverlay,
    BmNavigation
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (!value) {
        return callback(new Error(this.$t('login.notnull')))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error(this.$t('reg.emtail')))
        }
      }, 100)
    }
    return {
      list: [],
      active: false,
      dialogVisible: false,
      info: [],
      title: this.$t('guanyu.laochang'),
      address: this.$t('guanyu.laochangaddress'),
      key: 0,
      showinfo: false,
      serachname: '',
      type: 0,
      title: '',
      form: {},
      rules: {
        youxiang: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ],
        jianyi: [
          { required: true, message: this.$t('fankui.improvementSuggestionsmsg'), trigger: "blur" }
        ],
        country: [
          { required: true, message: 'Please fill in the country', trigger: "blur" }
        ],
      }
    }
  },
  mounted() {
    let lang = this.$i18n.locale
    this.mYecharts();
    let namemap = world.namemap
    let dataArr = world.dataArr
    this.getShiJieMap(namemap, dataArr)
    if(this.$i18n.locale == 'zh' || this.$i18n.locale == 'hk'){
        this.txinit()
    }
    //this.init()
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: 'keyWords',
          content: this.$t('seo.keywords')
        },
        {
          name: 'description',
          content: this.$t('seo.description')
        }
      ]
    }
  },
  created() {
    this.title = this.$t('layout.contact') + '-' + this.$t('seo.title')
    this.getList()
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          productscollectAdd(this.form).then(response => {
            Message({
              message: 'success',
              type: 'success'
            })
            this.form = {}
          })
        }
      });
    },
    init() {
      var myCenter = new google.maps.LatLng(34.800931566274116, 113.56453645559719);
      var mapProp = {
        center: myCenter,
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      var map = new google.maps.Map(document.getElementById("map"), mapProp);

      var marker = new google.maps.Marker({
        position: myCenter,
        icon: require('@/assets/lianxi/jinsuolu.png')
      });
      var myCenter2 = new google.maps.LatLng(34.82445938630299, 113.4872592822943);
      var marker2 = new google.maps.Marker({
        position: myCenter2,
        icon: require('@/assets/lianxi/tianjianhu.png')
      });
      marker.setMap(map);
      marker2.setMap(map);
      google.maps.event.addListener(marker, 'mouseover', () => {
        var infowindow = new google.maps.InfoWindow({
          content: this.$t('guanyu.laochang') + '</br>' + this.$t('guanyu.laochangaddress')
        });
        infowindow.open(map, marker);
      });
      google.maps.event.addListener(marker2, 'mouseover', () => {
        var infowindow = new google.maps.InfoWindow({
          content: this.$t('guanyu.xinchang') + '</br>' + this.$t('guanyu.xinchangaddress')
        });
        infowindow.open(map, marker2);
      });
    },
    txinit(){
      TMap.init().then((TMap) => {
            var center = new TMap.LatLng(34.800931566274116, 113.56453645559719);
            //初始化地图
            var map = new TMap.Map("tengxunmap", {
            zoom: 12, //设置地图缩放级别
            center: center, //设置地图中心点坐标
            });
            var marker = new TMap.MultiMarker({
            map: map,
            styles: {
                // 点标记样式
                jinsuolu: new TMap.MarkerStyle({
                width: 70, // 样式宽
                height: 90, // 样式高
                src: require('@/assets/lianxi/jinsuolu.png'),
                }),
                tianjianhu: new TMap.MarkerStyle({
                width: 70, // 样式宽
                height: 90, // 样式高
                src: require('@/assets/lianxi/tianjianhu.png'),
                }),
            },
            geometries: [
                {
                id:'jinsuolu',
                styleId: 'jinsuolu',
                position: center,
                },
                {
                id:'tianjianhu',
                styleId: 'tianjianhu',
                position: new TMap.LatLng(34.82445938630299, 113.4872592822943),
                },
            ],
            });
            var infoWindow = new TMap.InfoWindow({
                    map: map,
                    position: center,
                    offset: { x: 200, y: 100 } //设置信息窗相对position偏移像素
                });
                infoWindow.close();//初始关闭信息窗关闭
                //监听标注点击事件
                marker.on("mouseover",  (evt)=> {
                    //设置infoWindow
                    infoWindow.open(); //打开信息窗
                    infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                    let neirong = ''
                    let title =''
                    let address = ''
                    if(evt.geometry.id == 'jinsuolu'){
                    title = this.$t('guanyu.laochang')
                    address = this.$t('guanyu.laochangaddress')
                    neirong = "<image style='width:240px;'  src='"+require('@/assets/guanyu/jinsuolu.png')+"'></image><div><div class='infotitle' style='font-size: 20px;font-weight: bold;margin-top:20px'>"+title+"</div>"
                    }else{
                    title = this.$t('guanyu.xinchang')
                    address = this.$t('guanyu.xinchangaddress')
                    neirong = "<image style='width:240px;'  src='"+require('@/assets/guanyu/tianjianhu.png')+"'></image><div><div class='infotitle' style='font-size: 20px;font-weight: bold;margin-top:20px'>"+title+"</div>"
                    }
                    neirong = neirong+"<div class='infotitle' >"+this.$t('theFreeCall')+"：400-0318-908</div><div class='infotitle' >"+this.$t('guanyu.theForeignTradeInAStraightLine')+"：0086-371-67981000</div><div class='infotitle' >"+this.$t('guanyu.phone')+"：0086-371-67988888/67981888</div><div class='infotitle' style='padding-bottom:20px'>"+address+"</div></div>"
                    console.log(evt.geometry.id);
                    infoWindow.setContent(neirong);//设置信息窗内容
            })
        });
    },
    async getList() {
      const { data: res } = await contactusList()
      this.list = res
    },
    openinfo(key) {
      console.log(key);
      if (key == 1) {
        this.title = this.$t('guanyu.laochang')
        this.address = this.$t('guanyu.laochangaddress')
      } else if (key == 2) {
        this.title = this.$t('guanyu.xinchang')
        this.address = this.$t('guanyu.xinchangaddress')
      }
      this.key = key
      this.showinfo = true
    },
    guanbiinfo() {
      this.key = 0
      this.showinfo = false
    },
    draw({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(113.570653, 34.806515))
      el.style.left = pixel.x + 60 + 'px'
      el.style.top = pixel.y - 160 + 'px'
    },
    draw2({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(113.49371, 34.833641))
      el.style.left = pixel.x + 60 + 'px'
      el.style.top = pixel.y - 160 + 'px'
    },
    async open(class_id = '', type) {
      this.type = type
      let data = {
        class_id: class_id
      }
      const { data: res } = await bsUserList(data)
      res.forEach((item, index) => {
        if (item.wx) {
          item.wx = process.env.VUE_APP_BASE_API + item.wx
        }
      })
      this.info = res
      this.dialogVisible = true
    },
    mYecharts() {
      if (this.$i18n.locale == 'en') {
        echarts.registerMap("china", china_en, {});
      } else {
        echarts.registerMap("china", china, {});
      }
      //加载中国地图
      var myChart = this.$echarts.init(
        document.getElementById("dataMapecharts")
      );
      // 绘制图表
      var chinaMap = {
        tooltip: {
          show: true,
          formatter: (item) => {
            let resData = item.name;
            // console.log(item, resData);
            if (resData) {
              if (this.$i18n.locale == 'en') {
                return "Province:" + resData
              } else {
                return "省份:" + resData
              }
            }
          },
        },
        geo: {
          map: "china",
          roam: !1,
          scaleLimit: {
            min: 1,
            max: 2
          },
          zoom: 1,
          top: 80,
          label: {
            normal: {
              show: !0,
              fontSize: "14",
              color: "rgba(0,0,0,0.0)"
            },
            emphasis: {
              color: "rgba(0,0,0,0.0)"
            }
          },
          itemStyle: {
            normal: {
              //shadowBlur: 50,
              //shadowColor: 'rgba(0, 0, 0, 0.2)',
              borderColor: "#ccc",
              areaColor: '#0052D9'
            },
            emphasis: {
              areaColor: "#9DD4F4",
              shadowColor: "rgba(0, 0, 0, 0.5)",
            }
          }
        },
        series: [{
          type: "map",
          geoIndex: 0
        }]
      };
      myChart.setOption(chinaMap);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.on('click', async (params) => {
        let data = {
          key: params.name
        }
        const { data: res } = await bsUserList(data)
        if (res.length > 0) {
          res.forEach((item, index) => {
            if (item.wx) {
              item.wx = process.env.VUE_APP_BASE_API + item.wx
            }
          })
          this.type = 0
          this.info = res
          this.dialogVisible = true
        }

      });
    },
    async search() {
      let data = {
        key: this.serachname
      }
      const { data: res } = await bsUserList(data)
      res.forEach((item, index) => {
        if (item.wx) {
          item.wx = process.env.VUE_APP_BASE_API + item.wx
        }
      })
      this.info = res
      this.dialogVisible = true
    },
    getShiJieMap(name, data) {
      let chart = echarts.init(document.getElementById('shijiemap'))
      // 监听屏幕变化自动缩放图表
      window.addEventListener('resize', function () {
        chart.resize()
      })
      // 绘制图表
      chart.setOption({
        grid: {
          width: '100%',
          height: '100%',
          top: '50',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        // 提示框组件
        tooltip: {
          show: true,
          formatter: function (item) {
            let resData = item.name;
            if (resData) {
              return resData;
            }
          }
        },
        series: [
          {
            type: 'map', // 类型
            // 系列名称，用于tooltip的显示，legend 的图例筛选 在 setOption 更新数据和配置项时用于指定对应的系列
            name: '世界地图',
            mapType: 'world', // 地图类型
            // 是否开启鼠标缩放和平移漫游 默认不开启 如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move' 设置成 true 为都开启
            roam: false,
            // 图形上的文本标签
            label: {
              show: false // 是否显示对应地名
            },
            zoom: 1.2,
            // 地图区域的多边形 图形样式
            itemStyle: {
              normal: {
                borderColor: '#fff',//边界线颜色
                areaColor: '#0052D9'//默认区域颜色
              },
              // areaColor: '#7B68EE', // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
              borderWidth: 0.5, // 描边线宽 为 0 时无描边
              borderColor: '#000', // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
              borderType: 'solid' // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
            },
            // 高亮状态下的多边形和标签样式
            emphasis: {
              label: {
                show: true, // 是否显示标签
                color: 'rgba(0,0,0,0.0)' // 文字的颜色 如果设置为 'auto'，则为视觉映射得到的颜色，如系列色
              },
              itemStyle: {
                areaColor: '#9DD4F4' // 地图区域的颜色 
              }
            },
            // 自定义地区的名称映射
            nameMap: name,
            // 地图系列中的数据内容数组 数组项可以为单个数值
            data: data
          }
        ]
      })
      window.addEventListener("resize", function () {
        chart.resize();
      });
      chart.on('click', async (params) => {
        let data = {
          key: params.name
        }
        const { data: res } = await bsUserList(data)
        if (res.length > 0) {
          res.forEach((item, index) => {
            if (item.wx) {
              item.wx = process.env.VUE_APP_BASE_API + item.wx
            }
          })
          this.type = 1
          this.info = res
          this.dialogVisible = true
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
#dataMapecharts {
  width: 100%;
  height: 600px;
}
#shijiemap {
  width: 100%;
  height: 600px;
  margin-top: 50px;
}
.appmain {
  width: 75%;
}
@media screen and (max-width: 480px) {
  #dataMapecharts {
    height: 350px;
  }
  #shijiemap {
    height: 300px;
  }
  .appmain {
    width: 100%;
  }
  .title {
    margin-left: 20px;
  }
  .dialoglist {
    width: 100% !important;
  }
  .bm-view {
    height: 250px !important;
  }
}
@media screen and (max-width: 1540px) {
  .title {
    font-size: 18px !important;
  }
  .xtitle {
    font-size: 14px !important;
  }
  .dialoglist {
    width: 100% !important;
  }
  .bm-view {
    height: 250px !important;
  }
}
.title {
  font-size: 20px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
}
.xtitle {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  background: #fff;
  height: 60px;
  line-height: 60px;
  font-family: SourceHanSansCN-Bold;
  font-weight: bold;
  color: #333333;
  margin-bottom: 2px;
  cursor: pointer;
}
.xtitle:hover {
  background: #0052d9;
  color: #fff;
}
.shengming {
  margin-top: 3%;
  font-size: 16px;
  margin-left: -15px;
  color: #666666;
}
.bm-view {
  width: 100%;
  height: 600px;
  margin-top: 50px;
}
.info {
  width: 100%;
  padding: 20px;
  position: absolute;
  height: 330px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
  .infotitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .infoitem {
    margin-bottom: 15px;
  }
}
.infos {
  width: 300px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  font-weight: 400;
  color: #333333;
  .infotitle {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .infoitem {
    margin-bottom: 15px;
  }
}
.sample {
  width: 320px;
  overflow: hidden;
  position: absolute;
}
.sample.active {
}
.dialoglist {
  width: 30%;
  .dialogimage {
    width: 108px;
    margin: 0 auto;
    margin-bottom: 2%;
  }
  .dialoginfo {
    margin-bottom: 2%;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
    font-weight: 400;
    color: #333333;
  }
}
.gm-style .gm-style-iw-t::after {
    background: none;
    box-shadow:none;
}
</style>
<style scoped>
@media screen and (max-width: 480px) {
  /deep/ .el-dialog {
    width: 380px !important;
  }
}
</style>